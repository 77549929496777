import React, { Component } from "react";

import ClientAPI from "../service/ClientAPI";
import AdminAPI from "../service/AdminAPI";
import Searchbar from "../components/Searchbar";
import LocalStorageManager from "../managers/LocalStorageManager";
import MyProductsTable from "../components/MyProductsTable";
import CustomerProductsTable from "../components/CustomerProductsTable";
import Events from '../service/Events';

import 'react-calendar/dist/Calendar.css';
import { Button } from "@mui/material";

class DashboardAlleKunden extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            allOrders: [],
            searchUserInTable: null,
            searchProduct: null,
            searchUser: null,
            userOrders: [],
            createProduct: false,
            createUser: false,
            showDateTable: false,
            updateStartDate: false,
            updateEndDate: false,
            startDate: props.start,
            endDate: props.end,
            downloadingExcel: false
        };

        this.isAdmin = LocalStorageManager.Get("admin") === "1";
    }

    componentDidMount() {
        if (!this.isAdmin) {
            ClientAPI.GetOrders()
                .then((_orders) => {
                    this.setState({ userOrders: _orders });
                })
                .catch(console.error);

            return;
        }

        ClientAPI.RequestProducts()
            .then((_data) => {
                AdminAPI.FetchUserOrders()
                    .then(_allOrders => {
                        ClientAPI.GetOrders()
                            .then(_orders => {
                                this.setState({ products: _data, allOrders: _allOrders, userOrders: _orders })
                            })
                    })
                    .catch(console.error);
            })
            .catch(console.error);
    }

    RenderProductsOfUser = (admin = false) => {
        return (
            admin ?
                (
                    <div style={{ maxWidth: "95%", margin: "auto" }}>
                        <h3 id="myorders" style={{ textAlign: "center", marginTop: "20px" }}>Meine Bestellungen</h3>
                        <br />
                        <MyProductsTable orders={this.state.userOrders} />
                    </div>
                )
                :
                (
                    <div style={{ minHeight: "100vh", maxWidth: "95%", margin: "auto" }}>
                        <h3 id="myorders" style={{ textAlign: "center", marginTop: "20px" }}>Meine Bestellungen</h3>
                        <br />
                        <MyProductsTable orders={this.state.userOrders} />
                    </div>
                )
        );
    }

    RetrieveExcelFile = () => {
        this.setState({ downloadingExcel: true });

        AdminAPI.RequestExcelSheet()
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Customers.ods`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                this.setState({ downloadingExcel: false });
            })
            .catch((e) => {
                this.setState({ downloadingExcel: false });
            });
    }

    DeleteAllOrders = () => {
        Events.onAlert.emit("warning", "Möchten Sie alle Bestellungen von allen Kunden löschen?", "Wenn Sie alle Bestellungen löschen, werden alle Bestellungen gelöscht und zurückgesetzt, keine Kunde hat dann noch Bestellungen!"
            , () => {
                AdminAPI.DeleteAllOrders()
                    .then(() => window.location.reload())
            })
    }

    FilterUsers = () => {
        if (!this.state.searchUserInTable) {
            return this.state.allOrders;
        }

        const searchPattern = this.state.searchUserInTable.toLowerCase();

        return this.state.allOrders
            .filter(_user => {
                if (`${_user.firstName} ${_user.lastName}`.toLowerCase().includes(searchPattern)) {
                    return true;
                }

                if (_user.email && _user.email.toLowerCase().includes(searchPattern)) {
                    return true;
                }

                return false;
            });
    }

    render() {
        return (
            <div style={{ minHeight: "95vh" }}>
                <h3 id="tables" style={{ textAlign: "center", marginTop: "20px", marginBottom: "15px" }}>Übersicht der Kunden und deren Produkte</h3>
                <div style={{ marginBottom: "15px", maxWidth: "95%", display: "flex", justifyContent: "center", flexDirection: "column", margin: "auto", marginTop: "10px" }}>
                    <Button sx={{ maxWidth: "250px", marginBottom: "15px" }} onClick={this.DeleteAllOrders} variant="outlined" color="error">Alle Bestellungen löschen</Button>
                    <Searchbar addButton={true} customButton={{ name: "Download" }} loading={this.state.downloadingExcel} onCustomButton={this.RetrieveExcelFile} onAdd={() => this.setState({ createUser: true })} label={"Suche nach Kunden (Name oder E-Mail)"} searchRequest={(_searchStr) => this.setState({ searchUserInTable: _searchStr })} />
                    <CustomerProductsTable allOrders={this.state.allOrders} customers={this.FilterUsers()} products={this.state.products} />
                </div>
            </div>
        )
    }
}

export default DashboardAlleKunden;