import React, { Component } from "react";
import { Row } from "react-bootstrap";

import ChooseProductPopup from "../components/ChooseProductPopup";
import ProductItem from "../components/ProductItem";

import Searchbar from "../components/Searchbar";
import clientAPI from "../service/ClientAPI";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchProduct: null,
            products: [],
            showPopup: false,
            selectedProduct: null
        }
    }

    componentDidMount() {
        clientAPI.RequestProducts()
            .then(_products => {
                this.setState({ products: _products })
            })
            .catch(console.error);
    }

    selectedProduct = (product) => {
        this.setState({ selectedProduct: product, showPopup: true });
    }

    render() {
        return (
            <div style={{ marginBottom: "10px" }}>
                <div style={{ maxWidth: "95%", margin: "auto", minHeight: "100vh" }}>
                    <h2 style={{ textAlign: "center", marginBottom: "20px", marginTop: "25px" }}>Übersicht der Produkte</h2>
                    <Searchbar label={"Suche nach Produkt (Name)"} searchRequest={(_searchStr) => this.setState({ searchProduct: _searchStr })} />
                    <Row xs={1} md={5} className="g-4">
                        {
                            this.state.products
                                .filter(_product => _product.Published && 
                                    (this.state.searchProduct ? _product.Name.toLowerCase().includes(this.state.searchProduct.toLowerCase()) : true))
                                .sort((a, b) => {
                                    if (a.Name < b.Name) return -1;
                                    if (a.Name > b.Name) return 1;
                                    return 0;
                                })
                                .map(_product => {
                                return (
                                    <div key={_product.ID}>
                                        <ProductItem product={_product} selectedProduct={this.selectedProduct} />
                                    </div>
                                );
                            })
                        }
                    </Row>
                </div>
                <ChooseProductPopup show={this.state.showPopup} product={this.state.selectedProduct} onClose={() => this.setState({ showPopup: false })} />
            </div>
        )
    }
}

export default Home;