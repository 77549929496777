import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';

import LocalStorageManager from './managers/LocalStorageManager.js';
import CookieManager from './managers/CookieManager.js';

//#region Custom

import Navigation from './components/Navbar.jsx';
import ErrorPage from './pages/Error.jsx';
import Dashboard from './pages/Dashboard.jsx';
import Home from './pages/Home.jsx';

import ReLoginPopup from './components/ReLoginPopup.jsx';
import InfoSnackbar from './components/InfoSnackbar.jsx';
import Footer from './components/Footer.jsx';
import Register from './pages/Register.jsx';
import WarningPopup from './components/WarningPopup.jsx';

//#endregion Custom

import "./App.css";
import { Card } from 'react-bootstrap';
import { CardContent, Button, CardHeader } from '@mui/material';

import ClientAPI from './service/ClientAPI.js';
import UpdateShopOpenings from './components/UpdateShopDates.jsx';
import UpdateTotalWeight from './components/UpdateTotalWeight.jsx';
import Account from './pages/Account.jsx';

import 'bootstrap/dist/css/bootstrap.min.css';
import ResetPasswordPage from './pages/ResetPassword.jsx';
import Impressum from './pages/Impressum.jsx';
import InfoToPricingPage from './pages/InfoToPricing.jsx';
import AttentionToPricingAndOrdersPopup from './components/AttentionToPricingAndOrdersPopup.jsx';
import DashboardAlleKunden from './pages/DashboardAlleKunden.jsx';
import DashboardAlleProdukte from './pages/DashboardAlleProdukte.jsx';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      weight: 0,
      loaded: false,
      isLoggedIn: CookieManager.Get("auth") !== null,
      maxWeight: 3000,
      isAdmin: LocalStorageManager.Get("admin") === "1",
      showShopOpeningsPopup: false,
      showMaxWeightPopup: false
    }
  }

  componentDidMount() {
    ClientAPI.GetStoreInfo()
      .then((_data) => {
        this.setState({ startDate: new Date(_data.Start), endDate: new Date(_data.End), weight: _data.Weight, maxWeight: _data.MaxWeight, loaded: true });
      })
      .catch(_err => console.error(_err));
  }

  getOpeningTimeLeft() {
    const _currentDate = new Date();

    const _timeDiff = new Date(this.state.endDate).valueOf() - _currentDate.valueOf();
    const _daysLeft = _timeDiff / (24 * 60 * 60 * 1000);

    if (_daysLeft >= 1) {
      return `Noch ${_daysLeft.toFixed(0)} Tage offen`;
    } else if (_daysLeft < 0) {
      return `Der Shop ist geschlossen`;
    } else {
      return `Noch heute offen bis ${this.state.endDate.getHours()}:${this.state.endDate.getMinutes() < 10 ? "0" + this.state.endDate.getMinutes() : this.state.endDate.getMinutes()} Uhr`;
    }
  }

  handleChangeShopOpeningTimesClick = () => {
    this.setState({ showShopOpeningsPopup: true });
  }

  handleChangeTotalWeightClick = () => {
    this.setState({ showMaxWeightPopup: true });
  }

  calculatePercentageForWeight = () => {
    return ((this.state.weight / this.state.maxWeight) * 100).toFixed(2) + "%";
  }

  generateWeightStatusCard = () => {
    return (
      <Card style={{ marginTop: "8px", marginBottom: "8px" }} className='importantCard'>
        <CardContent>
          <p>Gesamtgewicht aller Bestellungen:</p>
          {
            this.state.isAdmin
              ?
              (
                <>
                  <p><b>{this.state.weight}Kg von {this.state.maxWeight}Kg ({this.calculatePercentageForWeight()})</b></p>
                  <Button variant="outlined" onClick={this.handleChangeTotalWeightClick}>Ändern</Button>
                </>
              )
              :
              (
                <>
                  <p><b>{this.calculatePercentageForWeight()} des Gesamtgewichts erreicht</b></p>
                </>
              )
          }
        </CardContent>
      </Card>
    );
  }

  generateShopClosingCard = () => {
    return (
      <Card style={{ marginTop: "8px" }} className='importantCard'>
        <CardContent>
          <p>Der Shop hat vom: {new Date(this.state.startDate).toLocaleDateString()} bis zum: {new Date(this.state.endDate).toLocaleDateString()} geöffnet</p>
          <p><b>{this.getOpeningTimeLeft()}</b></p>
          {
            this.state.isAdmin
              ?
              (
                <Button variant="outlined" onClick={this.handleChangeShopOpeningTimesClick}>Ändern</Button>
              )
              :
              <></>
          }
        </CardContent>
      </Card>
    )
  }

  generateImprint = () => {
    return (
      <div style={{ width: "85%", margin: "auto", marginTop: "20px" }}>
        <Card style={{ width: "300px" }}>
          <CardContent>
            <h6>Impressum</h6>
            <p>Peter Neff<br />
              Vo&szlig;bergweg 4<br />
              29476 Gu&szlig;born</p>

            <p>Telefon: +49 173 4828513<br />
              E-Mail: peter.b.neff@gmail.com</p>
          </CardContent>
        </Card>
      </div>
    )
  }

  RenderContent = () => {
    return (
      <div>
        {
          window.location.pathname !== "/impressum" ?
            (
              <div className="importantInfo">
                {
                  this.generateWeightStatusCard()
                }
                {
                  this.generateShopClosingCard()
                }
              </div>
            )
            :
            (
              this.generateImprint()
            )
        }
        <Switch>
          <Route path="/" component={Home} exact={true} />
          <Route path="/dashboard" exact={true} component={Dashboard} />
          <Route path="/dashboardCustomers" exact={true} component={DashboardAlleKunden} />
          <Route path="/dashboardProducts" exact={true} component={DashboardAlleProdukte} />
          <Route path="/register" component={Register} />
          <Route path="/account" component={Account} />
          <Route path="/resetPassword" component={ResetPasswordPage} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/info" component={InfoToPricingPage} />
          <Route component={ErrorPage} />
        </Switch>
        <Footer />
      </div>
    )
  }

  RenderContentForAnonymous = () => (
    <div>
      {
        window.location.pathname === "/impressum" ?
          (
            this.generateImprint()
          )
          :
          <></>
      }
      <Switch>
        <div style={{ minHeight: "95vh" }}>
          <Route path="/register" component={Register} />
          <Route path="/resetPassword" component={ResetPasswordPage} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/info" component={InfoToPricingPage} />
        </div>
      </Switch>
      <Footer />
    </div>
  )

  render() {
    return (
      <BrowserRouter style={{ minHeight: "100vh" }}>
        <div>
          <AttentionToPricingAndOrdersPopup />
          <ReLoginPopup />
          <WarningPopup />
          <Navigation />
          {
            this.state.isLoggedIn ? this.RenderContent() : this.RenderContentForAnonymous()
          }
          <InfoSnackbar />
        </div>
        <UpdateShopOpenings show={this.state.showShopOpeningsPopup} startDate={this.state.startDate} endDate={this.state.endDate} onClose={() => this.setState({ showShopOpeningsPopup: false })} />
        <UpdateTotalWeight show={this.state.showMaxWeightPopup} maxWeight={this.state.maxWeight} onClose={() => this.setState({ showMaxWeightPopup: false })} />
      </BrowserRouter>
    );
  }
}

export default App;
