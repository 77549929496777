import React, { Component } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import CookieManager from '../managers/CookieManager';

import Events from '../service/Events';

import LocalStorageManager from '../managers/LocalStorageManager';

class Navigation extends Component {
    RenderNavigation() {
        switch (LocalStorageManager.Get("admin")) {
            case "1":
                return (
                    <>
                        <Nav.Link href="/dashboard">Warenkorb</Nav.Link>
                        <Nav.Link href="/dashboardCustomers">Kunden</Nav.Link>
                        <Nav.Link href="/dashboardProducts">Produktübersicht</Nav.Link>
                    </>
                );
            case "0":
                return (
                    <Nav.Link href="/dashboard">Warenkorb</Nav.Link>
                );
            default:
                return (
                    <Nav.Link href="/dashboard">Warenkorb</Nav.Link>
                );
        }
    }

    signOut() {
        CookieManager.Delete("auth");
        LocalStorageManager.DeleteMultiple(["admin", "isSaved", "password", "email"]);

        window.location.reload();
    }

    signIn() {
        Events.onResult.emit("onAuthTokenInvalid");
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                <Container>
                    <Navbar.Brand href="/">Maro - Food
                        <span style={{ color: "gray" }}> (Bestell Food Coop)</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {this.RenderNavigation()}
                            <Nav.Link href="/">Produkte</Nav.Link>
                            <Nav.Link href="/account">Account</Nav.Link>
                            <Nav.Link href="/info" style={{ textDecorationLine: "underline" }}>Hinweis zu Preisen und Bestellungen</Nav.Link>
                            <Nav.Link href="/impressum" style={{ textDecorationLine: "underline" }}>Impressum</Nav.Link>
                        </Nav>
                        <Nav>
                            {
                                CookieManager.Get("auth")
                                    ?
                                    (
                                        <Nav.Link onClick={this.signOut}>Ausloggen</Nav.Link>
                                    )
                                    :
                                    (
                                        <Nav.Link onClick={this.signIn}>Anmelden</Nav.Link>
                                    )
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default Navigation;