import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Edit, Delete } from '@mui/icons-material';

import Events from '../service/Events';
import ClientAPI from '../service/ClientAPI';

import UpdateCustomerProductsPopup from './UpdateCustomerProductsPopup';
import { Button } from 'react-bootstrap';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name des Produkts',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: true,
    label: 'Anzahl des Produktes'
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: true,
    label: 'Preis',
  },
  {
    id: 'weight',
    numeric: false,
    disablePadding: true,
    label: 'Gewicht',
  },
  {
    id: 'totalPrice',
    numeric: false,
    disablePadding: true,
    label: 'Gesamtpreis',
  },
  {
    id: 'totalWeight',
    numeric: false,
    disablePadding: true,
    label: 'Gesamtgewicht',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all products',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected, onEdit, onConfirm, onDeleteOrders, orders } = props;
  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {`Meine Produkte (Gesamtpreis: ${orders.length ? orders.map(o => o.Price * o.Amount).reduce((a, b) => a + b).toFixed(2) : 0}€)`}
          </Typography>
        )}

        {numSelected > 0 ? (
          <>
            <Button style={{ margin: "10px" }} onClick={onConfirm}>Bestätigen</Button>
            <Tooltip title="Löschen der selektierten Bestellungen" onClick={() =>
              Events.onAlert.emit("warning", "Möchten Sie alle ausgewählten Bestellungen löschen?", "Wenn Sie alle ausgewählten Bestellungen löschen, können Sie dieses Element nicht mehr bearbeiten und müssen sich das Produkt erneut hinzufügen"
                , () => {
                  onDeleteOrders();
                })
            }>
              <IconButton>
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Bearbeiten der selektierten Bestellung" onClick={() => onEdit()}>
              <IconButton>
                <Edit />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <></>
        )}
      </Toolbar>
    </>
  );
};

export default class MyProductsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'price',
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 5,
      orders: [],
      show: false
    };
  }

  componentDidUpdate() {
    if (this.state.orders !== this.props.orders) {
      this.setState({ page: 0, orders: this.props.orders })
    }
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = (event) => {
    if (this.state.selected == null || this.state.selected.length <= 0) {
      this.setState({ selected: this.state.orders.filter(_order => !_order.Confirmed) });
      return;
    }

    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const selectedIndex = this.state.orders.findIndex(p => p.orderID === id);

    if (selectedIndex === -1) {
      return;
    }

    if (this.state.orders[selectedIndex].Confirmed) {
      return;
    }

    const copiedArr = Array.from(this.state.selected);

    if (copiedArr.length <= 0) {
      copiedArr.push(this.state.orders[selectedIndex]);

      this.setState({ selected: copiedArr });
      return;
    }

    const ind = copiedArr.findIndex(p => p.orderID === id);

    if (ind !== -1) {
      copiedArr.splice(ind, 1)
    } else {
      copiedArr.push(this.state.orders[selectedIndex]);
    }

    this.setState({ selected: copiedArr });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleChangeDense = (event) => {
    this.setState({ dense: event.target.checked });
  };

  isSelected = (id) => this.state.selected.findIndex(s => s.orderID === id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  emptyRows =
    this.state ? this.state.page > 0 ? Math.max(0, (1 + this.state.page) * this.state.rowsPerPage - this.state.orders.length) : 0 : 5;

  deleteOrders = () => {
    ClientAPI.DeleteOrders({ orders: this.state.selected })
      .then(() => {
        window.location.reload();
      })
      .catch(console.error)
  }

  confirmOrders = () => {
    Events.onAlert.emit('warning', "Möchten Sie alle ausgewählten Bestellungen bestätigen?", "Wenn Sie die Bestellungen bestätigen, können Sie die Bestellungen nicht mehr bearbeiten und auch keine neuen Produkte, von dieser Art, hinzufügen!", () => {
      ClientAPI.ConfirmOrders(this.state.selected.map(order => order.orderID))
        .then(() => {
          window.location.reload();
        })
        .catch(console.error);
    });
  }

  displayOrderData = (order, amount, type) => order.isFixed ? `${((type === "€" ? order.Price : order.Weight) * amount).toFixed(2)}${type}` : order.CustomPrice !== 0 ? `${type === "€" ? order.CustomPrice : order.CustomWeight}${type}` : "wird berechnet...";

  render() {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar orders={this.state.orders} onConfirm={this.confirmOrders} numSelected={this.state.selected.length} onEdit={() => this.setState({ show: true })} onDeleteOrders={() => this.deleteOrders()} />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={this.state.dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={this.state.selected.length}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.orders ? this.state.orders.length : 0}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                          rows.slice().sort(getComparator(order, orderBy)) */}
                  {this.state && this.state.orders && this.state.orders
                    //.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .sort((a, b) => a - b)
                    .map((order, index) => {
                      const isItemSelected = this.isSelected(order.orderID);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover={!order.Confirmed}
                          onClick={(event) => this.handleClick(event, order.orderID)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={order.orderID}
                          selected={isItemSelected}
                          sx={{ backgroundColor: order.Confirmed ? "#c7c7c7" : "#fff" }}
                        >
                          <TableCell padding="checkbox">
                            {
                              !order.Confirmed ?
                                (
                                  <Checkbox
                                    color="primary"
                                    checked={order.Confirmed ? false : isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                )
                                :
                                <></>
                            }
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {order.Name}
                          </TableCell>
                          <TableCell>{`${order.Confirmed ? "Bestellung bestätigt" : "In Bearbeitung..."}`}</TableCell>
                          <TableCell>{`${order.Amount}x`}</TableCell>
                          <TableCell>{this.displayOrderData(order, 1, "€")}</TableCell>
                          <TableCell>{this.displayOrderData(order, 1, "Kg/L")}</TableCell>
                          <TableCell>{this.displayOrderData(order, !order.isFixed ? 1 : order.Amount, "€")}</TableCell>
                          <TableCell>{this.displayOrderData(order, !order.isFixed ? 1 : order.Amount, "Kg/L")}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Zeilen pro Spalte:"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.orders.length}
              rowsPerPage={-1}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        <UpdateCustomerProductsPopup show={this.state.show} orders={this.state.selected} onClose={() => this.setState({ show: false })} />
      </>
    );
  }
}